<template>
  <div class="Recruitment">
      <div class="topBanner container">
          <h1 data-aos="fade-up" data-aos-duration="1500">JUSTIN CAREERS</h1>
      
        </div>
      </div>

      <div class="careersCon container">
        <h2 class="f11" style="margin-bottom:2rem;" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="200">{{ careersTit[0] }}</h2>
        <div class="careersGroup">
          <div v-for="(data, index) in data" :key="index" class="careers">
              <div :style="{ backgroundImage: 'url(' + data.img + ')' }" data-aos="fade-up" data-aos-duration="1300" :data-aos-delay="index+2+'00'">
                <p class="careersTit f8">{{ data.careersTit }}</p>
                <p class="careersSub f5">{{ data.careersSub }}</p>
              </div>
          </div>
        </div>
      </div>

      <div class="careersCon2">
        <h2 class="f11" data-aos="fade-up" data-aos-duration="1500">{{ careersTit[1] }}</h2>
        <p class="subTit f7" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="50" v-html="careersSubTit"></p>
        <p class="circleTxt f8" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">{{ careersCircleTxt }}</p>
        <div class="careersCon2Group " data-aos="fade-up" data-aos-duration="1500" data-aos-delay="150">
          <div>
            <p class="f7">{{ careerswWlfare[0] }}</p>
            <p class="f7">{{ careerswWlfare[1] }}</p>
          </div>
          <div>
              <p class="f7">{{ careerswWlfare[2] }}</p>
              <p class="f7">{{ careerswWlfare[3] }}</p>
          </div>
          <div>
            <p class="f7">{{ careerswWlfare[4] }}</p>
            <p class="f7">{{ careerswWlfare[5] }}</p>
          </div>
      </div>
    </div>
    <div class="tableCon container">
      <h2 class="f11 center b9" data-aos="fade-up" data-aos-duration="1500">채용포지션(상시 채용)</h2>
      <span class="line"></span>
      <table class="careers-table" data-aos="fade-up" data-aos-duration="1500">
        <tr>
          <th>직무</th>
          <th>내용</th>
        </tr>
        <tr v-for="(item, index) in careersData" :key="index">
          <td>{{ item.careersTit }}</td>
          <td>· {{ item.careersSub }}</td>
        </tr>
      </table>
    </div>



    <div class="f6 newsView"  data-aos="fade-up" data-aos-duration="1500">
      <a class="viewBtn" href="https://www.saramin.co.kr/zf_user/company-info/view-inner-recruit?csn=MTJSbFQrNmlMa0NkT0VOekVkaXFGZz09" target="_blank">
        <img :src="require('/public/img/Sitelogo01.png')" alt="사람인 로고">
        <span>채용공고 보러가기</span>
      </a>
      <a class="viewBtn" href="https://www.jobkorea.co.kr/Recruit/Co_Read/C/32010147?Oem_Code=C1" target="_blank">
        <img :src="require('/public/img/Sitelogo02.png')" alt="잡코리아 로고">
        <span>채용공고 보러가기</span>
      </a>
    </div>
   
    

   
</template>

<script>
export default {
  name: 'RecruitmentComponent',
  data(){
    return{
      title:"CAREERS",

      careersTit:['인재상','복리후생'],
      careersSubTit:'<span>임직원의 건강과 행복,<br>Work & Life Balance 를 지원하기 위해<br>다양한 복리후생을 지원하고 있습니다.</span>',
      careersCircleTxt:'Work & Life Balance 를 위한 제도',
      careerswWlfare:['퇴직연금 인센티브제, 상여금','경조사비 및 경조 휴가 지원','장기근속자포상','자유로운 연차 사용','자사/ 자회사/ 계열사 제품 할인','정시 출퇴근 문화 확립'],
      careersSubmit:'지원하기',
      careersData: [
        {
          careersTit: '푸드스타일팀 스타일파트',
          img: require('/public/img/recruitment1.png'),
          careersSub: '행사 PM 및 푸드스타일링'
        },
        {
          careersTit: '푸드스타일팀 쿡파트',
          img: require('/public/img/recruitment2.png'),
          careersSub: '메인 음식 조리 및 쉐프 출장'
        },
        {
          careersTit: '코디네이터팀',
          img: require('/public/img/recruitment3.png'),
          careersSub: '케이터링 고객 상담 및 견적 작성, 고객 케어'
        },
        {
          careersTit: '비쥬얼디렉팅팀',
          img: require('/public/img/recruitment4.png'),
          careersSub: '행사 관련 비주얼 업무 전반(브랜딩, 플라워, 공간연출 등)'
        },
        {
          careersTit: '물류팀',
          img: require('/public/img/recruitment1.png'),
          careersSub: '물류 배송 및 행사장 세팅'
        },
        {
          careersTit: '마케팅컨텐츠팀',
          img: require('/public/img/recruitment2.png'),
          careersSub: '행사 촬영 및 케이터링 포토폴리오 제작'
        },
        {
          careersTit: '서비스경영팀',
          img: require('/public/img/recruitment3.png'),
          careersSub: 'VIP 고객 전담 케어 및 리스크 관리'
        },
        {
          careersTit: '자재팀',
          img: require('/public/img/recruitment4.png'),
          careersSub: '비품, 식자재 구매 및 관리'
        },
      ],
      data:[
        {
          careersTit:'고객중심',
          img: require('/public/img/recruitment1.png'),
          careersSub:'고객의 시각에서 니즈를 파악하고 고객 감동을 실현할 수 있는 가치를 제공하는 사람',
        },{
          careersTit:'창의성',
          img:require('/public/img/recruitment2.png'),
          careersSub:'창의적인 사고로 변화를 추구하고 혁신을 이끌어 갈 사람',
        },{
          careersTit:'핵심직무',
          img:require('/public/img/recruitment3.png'),
          careersSub:'자발적이고 주도적인 마인드로 새로운 가치를 위해 끊임없이 발전하기 위해 노력하는 사람',
        },{
          careersTit:'핵심가치',
          img:require('/public/img/recruitment4.png'),
          careersSub:'자기개발을 통해 전문적인 능력을 배양하고 도전정신과 열정을 가지고 자신과 조직에 자부심을 가진 사람',
        },
      ]
    }
  },

}
</script>
<style scoped>
  .careersCon {margin-top:0 !important;}
  .careersCon h2, .careersCon2 h2{text-align: center; font-weight:900;}
  .careersGroup{
    display:flex; justify-content: space-between; align-items: center;
  
  }
  .careers {
    width: 24%;
    aspect-ratio: 349/485;
    border-radius: 30px;
    position: relative;
  
   
  }
  .careers > div{
    margin-top: 5%;
    width: 100%;
    height: 100%;

    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    padding:4% 4% 12% 4%;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
   
}
/* 클래스 .careers의 자식으로 .bg-opacity 클래스 추가 */
.careersGroup .careers > div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3); /* 백그라운드 이미지 위에 투명도 0.7로 설정 */
  z-index:1;
}


.careersGroup .careers > div:hover::before {
  background-color: rgba(255, 255, 255, 0); 
  z-index:1;
}
.careersGroup .careers > div:hover{
color:#fff;
}
.careersGroup .careers p{
 
  z-index: 2;
}

  .careers p{text-align: center;}
  .careersTit{font-weight: 800; margin-bottom: 30px; }
  .careers .careersSub{ 
    line-height: 1.2; 
    font-weight: 600;
    width: 90%;
    margin: 0 auto;
    word-break: keep-all;
  }
  .careersCon2{background: #ffcfcf; padding:7% 0; text-align: center;}
  .subTit{margin-top:40px; line-height: 2rem;}
  .circleTxt{padding:15px 25px; background: #fff; color:#000; font-weight: 700; border-radius: 40px; display: inline-block; margin: 3% 0;} 
  .careersCon2Group{
    border-radius: 20px; 
    padding:1% 3%; 
    background: #fff; 
    display:flex; 
    justify-content: space-between; 
    align-items: center; 
    flex-wrap: wrap; 
    box-sizing: border-box;
    width: 80%;
    margin: 0 auto;
  } 
  .careersCon2Group > div{
    width: 20rem;
    margin: 2% 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 5rem;
  
  }
  .careersCon2Group > div p{
   text-align: left;
  }
  
  .careersCon2Group p{
    width: 100%;
    text-align: left; margin: 1% 0; font-weight: 600;}
  .careersCon2Group p::before{content: ''; width:7px; height:7px; border-radius: 50%; background: #c37d7d; display: inline-block; margin: 0 1% 1% 0;}




  
.tableCon{
  width: 100%;
}
.tableCon h2{
margin-bottom:3rem;
}
.careers-table {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  margin-top: 3rem

}
.careers-table tr td:last-child {
  text-align: left;
  font-weight: 400;
  color: #757575; /* 헤더 글자색 */
}

.careers-table th,
.careers-table td {
  padding: 30px;
  text-align: center;
  border-bottom: 1px solid #eaeaea; /* 하단 경계선 */
font-weight: 600;
color: #000
}


.careers-table td:first-child , .careers-table th:first-child {
  border-right: 1px solid #eaeaea; 
}

.careers-table th {
  border-top: 2px solid #000; /* 상단 경계선 */

  font-size: 16px; /* 글자 크기 */
  font-weight: bold;
}

.careers-table td {

  font-size: 14px;
}

.careers-table tr:last-child td {
  border-bottom: none; /* 마지막 행의 하단 경계선 제거 */

}
.newsView{
  display: flex ;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}
.viewBtn {
  width: 40%;
    display: flex;
    border-radius: 1rem;

    padding: 1rem;
    text-align: center;
    margin-top: 6vh;
    font-weight: 500;
    color: #fff;
    border: 0;
    transition: .3s;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #f4f4f4;
    color: #000;
    border:1px solid #000;
}
.viewBtn:hover {
    opacity: .6;
    border:0;
}


.viewBtn img {
    max-width: 150px;
    width: 100%;
}
.viewBtn span {
  color: #000;
    font-size: 1.3rem;
}


  @media screen and (max-width: 1400px) {
    .careersCon2Group{
      width: 90%;}
  }
  @media screen and (max-width: 1200px) {
    .careersCon2Group{
      width: 98%;}
  }

 
  @media screen and (max-width: 990px) {
    .careersCon2Group{
      width: 90%;
      flex-direction: column;
    }
    .careersCon2Group > div{
      width: 100%;
    }

    .careers {
      aspect-ratio: 1/1.39;
      width: 48%;
      padding: 4% 4% 8% 4%;
      padding:0;  
    }
    .careersGroup{
      flex-wrap: wrap;
    }
    
  }
  @media screen and (max-width:480px){

    .careersCon2Group{
      width: 95%;
      justify-content: center;
      height: 25rem;
    }
    .careers {
   
      width: 92%;
      margin-left: 4%;
  
    }

  .careersCon2Group p{
    width: 100%;
   
  }
  }
  @media screen and (max-width: 260px) {
    .careersCon2{
     display: none;
    }
  }
</style>
